import React, { useState } from "react";
import MessageHelper from "../../../../entities/message/message-helper";
import ThreadHelper from "../../../../entities/thread/thread-helper";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import Events from "../../../../const/events";
import { useSelector } from "react-redux";

const MessageComposer = ({ onSendMessage = () => {}, canSend = true, selectedThread }) => {
  const [message, setMessage] = useState(""); // State to track the message being typed
  const [isSending, setIsSending] = useState(false); // State to track if message is being sent

  const templates = useSelector(
    (state) => state?.templateStore?.templates || []
  );

  // Handle input change
  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  // Function to send message
  const handleSendMessage = async () => {
    if (message.trim() === "" || isSending) return; // Do not send empty messages
    if (!canSend) {
      EventEmitter.dispatch(Events.SHOW_TWILIO_SANDBOX_WARNING);
      return;
    }
    setIsSending(true); // Disable input while sending message
    try {
      await ThreadHelper.sendMessage({ messageText: message }); // Send message
      onSendMessage();
      setMessage(""); // Clear input after message is sent
    } catch (error) {
      console.error("Error sending message:", error);
    }
    setIsSending(false); // Re-enable input after message is sent
  };

  // Handle Enter key to send message
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !isSending) {
      e.preventDefault(); // Prevent newline insertion
      handleSendMessage();
    }
  };

  const _sendTemplate = (template) => {
    console.log('template :>> ', template);
    MessageHelper.sendTemplateMessage(template, selectedThread);
    // hide
    setModalShow(false);
  };

  const [inputActive, setInputActive] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className={"chat-footer " + (inputActive ? "active" : "")}>
      <div className="typing-area">
        <span className="chat-admin">
          <img
            src="assets/images/profile-avatar.png"
            alt="user"
            className="rounded-circle thumb-sm"
          />
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Type something here..."
          value={message}
          onClick={(e) => setInputActive(true)}
          onBlur={(e) => setInputActive(false)}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress} // Handle Enter key press
          disabled={isSending} // Disable input while sending
          style={isSending ? { opacity: 0.5 } : {}} // Reduce opacity while sending
        />
      </div>

      <div className="actions-area">
        <div
          className="selectTemplateToggle"
          title="Select template"
          onClick={(e) => setModalShow(true)}
        >
          <i className="fa fa-puzzle-piece"></i>
        </div>

        <div className="chat-features">
          {message.trim() !== "" && ( // Conditionally show the send button
            <a href="#" onClick={handleSendMessage} title="Send message">
              <i className="la la-paper-plane" />
            </a>
          )}
        </div>
      </div>

      <div className={"selectTemplateModal " + (modalShow ? "show" : "")}>
        <div className="overlay" onClick={(e) => setModalShow(false)}></div>

        <div className="inner">
          <div class="modalHeader">
            <h6 class="modalTitle">Select a template</h6>
            <span className="closeModal" onClick={(e) => setModalShow(false)}>
              <i className="la la-times"></i>
            </span>
          </div>

          <div className="textTemplates">
            {templates
              ?.filter((temp) => temp?.types["twilio/text"])
              .map((template, index) => (
                <div className="textTemplate" key={index}
                  onClick={(e) => {
                    _sendTemplate(template);
                  }}
                >
                  {template?.types["twilio/text"]?.body}
                </div>
              ))}
          </div>

          <div className="modalButtons text-end mt-4">
            <span
              className="btn btn-muted me-2"
              onClick={(e) => setModalShow(false)}
            >
              Cancel
            </span>
            <span className="btn btn-success">Insert template</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageComposer;
