import React, { useState, useEffect } from 'react';
import "./manage-users.scss";

const ManageUsersPage = () => {
    return (
        <>
            {/* leftbar-menu */}
            <div className="left-sidebar">
                {/* LOGO */}
                <div className="brand">
                    <a href="index.html" className="logo">
                        <span>
                            <img src="assets/images/logo-sm.png" alt="logo-small" className="logo-sm" />
                        </span>
                        <span>
                            <img src="assets/images/logo.png" alt="logo-large" className="logo-lg logo-light" />
                            <img src="assets/images/logo-dark.png" alt="logo-large" className="logo-lg logo-dark" />
                        </span>
                    </a>
                </div>
                <div className="sidebar-user-pro media border-end">
                    <div className="position-relative mx-auto">
                        <img src="assets/images/users/user-4.jpg" alt="user" className="rounded-circle thumb-md" />
                        <span className="online-icon position-absolute end-0"><i className="mdi mdi-record text-success" /></span>
                    </div>
                    <div className="media-body ms-2 user-detail align-self-center">
                        <h5 className="font-14 m-0 fw-bold">Mr. Michael Hill </h5>
                        <p className="opacity-50 mb-0">michael.hill@exemple.com</p>
                    </div>
                </div>
                {/* Tab panes */}
                {/*end logo*/}
                <div className="menu-content h-100" data-simplebar>
                    <div className="menu-body navbar-vertical">
                        <div className="collapse navbar-collapse tab-content" id="sidebarCollapse">
                            {/* Navigation */}
                            <ul className="navbar-nav tab-pane active" id="Main" role="tabpanel">
                                <li className="menu-label mt-0 text-primary font-12 fw-semibold">M<span>ain</span><br /><span className="font-10 text-secondary fw-normal">Admin controls</span></li>
                                
                                
                                <li className="nav-item">
                                    <a className="nav-link" href="apps-chat.html"><i className="ti ti-brand-hipchat menu-icon" /><span>Chats</span></a>
                                </li>{/*end nav-item*/}
                                <li className="nav-item">
                                    <a className="nav-link" href="apps-contact-list.html"><i className="ti ti-users menu-icon" /><span>Manage users</span></a>
                                </li>{/*end nav-item*/}

                            </ul>
                            <ul className="navbar-nav tab-pane" id="Extra" role="tabpanel">
                                <li>
                                    <div className="update-msg text-center position-relative">
                                        <button type="button" className="btn-close position-absolute end-0 me-2" aria-label="Close" />
                                        <img src="assets/images/speaker-light.png" alt className height={110} />
                                        <h5 className="mt-0">Mannat Themes</h5>
                                        <p className="mb-3">We Design and Develop Clean and High Quality Web Applications</p>
                                        <a href="javascript: void(0);" className="btn btn-outline-warning btn-sm">Upgrade your plan</a>
                                    </div>
                                </li>
                            </ul>{/*end navbar-nav-*/}
                        </div>{/*end sidebarCollapse*/}
                    </div>
                </div>
            </div>
            {/* end left-sidenav*/}
            {/* end leftbar-menu*/}
            {/* Top Bar Start */}
            {/* Top Bar Start */}
            <div className="topbar">
                {/* Navbar */}
                <nav className="navbar-custom" id="navbar-custom">
                    <ul className="list-unstyled topbar-nav float-end mb-0">
                        <li className="dropdown">
                            <a className="nav-link dropdown-toggle nav-user" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <img src="assets/images/users/user-4.jpg" alt="profile-user" className="rounded-circle me-2 thumb-sm" />
                                    <div>
                                        <small className="d-none d-md-block font-11">Admin</small>
                                        <span className="d-none d-md-block fw-semibold font-12">Maria Gibson <i className="mdi mdi-chevron-down" /></span>
                                    </div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#"><i className="ti ti-user font-16 me-1 align-text-bottom" /> Profile</a>
                                {/* <a className="dropdown-item" href="#"><i className="ti ti-settings font-16 me-1 align-text-bottom" /> Settings</a> */}
                                <div className="dropdown-divider mb-0" />
                                <a className="dropdown-item" href="#"><i className="ti ti-power font-16 me-1 align-text-bottom" /> Logout</a>
                            </div>
                        </li>{/*end topbar-profile*/}
                    </ul>
                    {/*end topbar-nav*/}
                    <ul className="list-unstyled topbar-nav mb-0">
                        <li>
                            <button className="nav-link button-menu-mobile nav-icon" id="togglemenu">
                                <i className="ti ti-menu-2" />
                            </button>
                        </li>
                        {/* <li className="hide-phone app-search">
                            <form role="search" action="#" method="get">
                                <input type="search" name="search" className="form-control top-search mb-0" placeholder="Type text..." />
                                <button type="submit"><i className="ti ti-search" /></button>
                            </form>
                        </li> */}
                    </ul>
                </nav>
                {/* end navbar*/}
            </div>
            {/* Top Bar End */}
            {/* Top Bar End */}
            <div className="page-wrapper">
                {/* Page Content*/}
                <div className="page-content-tab">
                    <div className="container-fluid">
                        {/* Page-Title */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="float-end">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                            <li className="breadcrumb-item active">Manage Users</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title"> Manage Users </h4>
                                </div>
                                {/*end page-title-box*/}
                            </div>
                            {/*end col*/}
                        </div>
                    </div>{/* container */}
                    {/*Start Rightbar*/}
                    {/*Start Rightbar/offcanvas*/}
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">    
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>Users</th>
                                                <th>Rols</th>
                                                <th>Email</th>
                                                <th>Contact No</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <span class="thumb-sm justify-content-center d-inline-flex align-items-center bg-soft-primary rounded-circle me-2">DP</span>
                                                    Aaron Poulin
                                                </td>
                                                <td>Agent</td>
                                                <td>AaronPoulin@example.com</td>
                                                <td>+21 123456789</td>
                                                <td><span class="badge badge-soft-success">Active</span></td>
                                                <td>                                                       
                                                    <button className="btn btn-soft-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#newUserAddorEditModal"><i class="las la-pen font-16"></i></button>
                                                    <button className="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUserConfirmationModal"><i class="las la-trash-alt font-16"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><img src="assets/images/users/user-4.jpg" alt="" class="rounded-circle thumb-sm me-1" /> Richard Ali</td>
                                                <td>Administrator</td>
                                                <td>RichardAli@example.com</td>
                                                <td>+41 123456789</td>
                                                <td><span class="badge badge-soft-success">Active</span></td>
                                                <td>                                                       
                                                    <button className="btn btn-soft-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#newUserAddorEditModal"><i class="las la-pen font-16"></i></button>
                                                    <button className="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUserConfirmationModal"><i class="las la-trash-alt font-16"></i></button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><img src="assets/images/users/user-5.jpg" alt="" class="rounded-circle thumb-sm me-1" /> Juan Clark</td>
                                                <td>Contributor</td>
                                                <td>JuanClark@example.com</td>
                                                <td>+65 123456789</td>
                                                <td><span class="badge badge-soft-success">Active</span></td>
                                                <td>                                                       
                                                    <button className="btn btn-soft-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#newUserAddorEditModal"><i class="las la-pen font-16"></i></button>
                                                    <button className="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUserConfirmationModal"><i class="las la-trash-alt font-16"></i></button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><img src="assets/images/users/user-6.jpg" alt="" class="rounded-circle thumb-sm me-1" /> Albert Hull</td>
                                                <td>Agent</td>
                                                <td>AlbertHull@example.com</td>
                                                <td>+88 123456789</td>
                                                <td><span class="badge badge-soft-success">Active</span></td>
                                                <td>                                                       
                                                    <button className="btn btn-soft-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#newUserAddorEditModal"><i class="las la-pen font-16"></i></button>
                                                    <button className="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUserConfirmationModal"><i class="las la-trash-alt font-16"></i></button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><img src="assets/images/users/user-7.jpg" alt="" class="rounded-circle thumb-sm me-1" /> Crystal Darling</td>
                                                <td>Contributor</td>
                                                <td>CrystalDarling@example.com</td>
                                                <td>+56 123456789</td>
                                                <td><span class="badge badge-soft-danger">Deactivated</span></td>
                                                <td>                                                       
                                                    <button className="btn btn-soft-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#newUserAddorEditModal"><i class="las la-pen font-16"></i></button>
                                                    <button className="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUserConfirmationModal"><i class="las la-trash-alt font-16"></i></button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><img src="assets/images/users/user-8.jpg" alt="" class="rounded-circle thumb-sm me-1" /> Thomas Milligan</td>
                                                <td>Administrator</td>
                                                <td>homasMilligan@example.com</td>
                                                <td>+35 123456789</td>
                                                <td><span class="badge badge-soft-danger">Deactivated</span></td>
                                                <td>                                                       
                                                    <button className="btn btn-soft-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#newUserAddorEditModal"><i class="las la-pen font-16"></i></button>
                                                    <button className="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUserConfirmationModal"><i class="las la-trash-alt font-16"></i></button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button class="btn btn-primary btn-sm px-4" data-bs-toggle="modal" data-bs-target="#newUserAddorEditModal">+ Add New</button>
                                        </div>
                                        <div class="col-auto">
                                            <nav aria-label="...">
                                                <ul class="pagination pagination-sm mb-0">
                                                    <li class="page-item disabled">
                                                        <a class="page-link" href="#" tabindex="-1">Previous</a>
                                                    </li>
                                                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                         </div> 
                                    </div>




                                    <div class="modal fade" id="newUserAddorEditModal" tabindex="-1" role="dialog" aria-labelledby="newUserAddorEditModalTitle" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h6 class="modal-title m-0" id="newUserAddorEditModalTitle">Add/Edit User</h6>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-5 text-center align-self-center">
                                                            <img src="assets/images/small/add-user.svg" alt="" class="img-fluid d-none d-md-block" />
                                                        </div>
                                                        <div class="col-lg-7">
                                                            <h5 className="mb-4">Edit Details for user <b>John Doe</b></h5>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div class="form-group mb-4 d-flex align-items-center">
                                                                        <label for="validationCustom02" class="form-label mb-0">Account Status</label>
                                                                        <div class="form-check form-switch ms-3">
                                                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked />
                                                                            <label class="form-check-label" for="flexSwitchCheckDefault">Active</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div class="form-group mb-3">
                                                                        <label for="validationCustom01" class="form-label mb-0">First name</label>
                                                                        <input type="text" class="form-control" placeholder="First name" aria-label="mobile-number" aria-describedby="basic-addon1" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <div class="form-group">
                                                                        <label for="validationCustom02" class="form-label mb-0">Last name</label>
                                                                        <input type="text" class="form-control" placeholder="Last name" aria-label="mobile-number" aria-describedby="basic-addon1" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <div class="form-group">
                                                                        <label for="validationCustom02" class="form-label mb-0">Email address</label>
                                                                        <input type="text" class="form-control" placeholder="Email address" aria-label="mobile-number" aria-describedby="basic-addon1" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="validationCustom02" class="form-label mb-0">Role</label>
                                                                        <select class="form-select" aria-label="Default select example">
                                                                            <option selected>-- Select a role --</option>
                                                                            <option value="1">Admin</option>
                                                                            <option value="2">Editor</option>
                                                                            <option value="3">Moderator</option>
                                                                            <option value="4">User</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer d-flex align-items-center justify-content-between">
                                                    <button type="button" class="btn btn-soft-danger btn-sm" data-bs-dismiss="modal">Close</button>
                                                    <button type="button" class="btn btn-primary btn-sm">Save changes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="modal fade" id="deleteUserConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="deleteUserConfirmationModal1" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header bg-danger">
                                                    <h6 class="modal-title m-0 text-white" id="deleteUserConfirmationModal1">Confirm deletion of user</h6>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row align-items-center">
                                                        <div class="col-lg-3 text-center align-self-center">
                                                            <img src="assets/images/small/delete-user.png" alt="" class="img-fluid" />
                                                        </div>
                                                        <div class="col-lg-9">
                                                            <h5>You're about to delete user <b>John Doe</b>!</h5>
                                                            <h5>Are you sure? </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer d-flex align-items-center justify-content-between">
                                                    <button type="button" class="btn btn-de-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                                                    <button type="button" class="btn btn-de-danger btn-sm">Yes, delete this user</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    {/*end Rightbar/offcanvas*/}
                    {/*end Rightbar*/}
                    {/*Start Footer*/}
                    {/* Footer Start */}
                    <footer className="footer text-center text-sm-start">
                        © Doxa <span className="text-muted d-none d-sm-inline-block float-end">Crafted with <i className="mdi mdi-heart text-danger" /> by Doxa</span>
                    </footer>
                    {/* end Footer */}
                    {/*end footer*/}
                </div>
                {/* end page content */}
            </div>
            {/* end page-wrapper */}


        </>
    );
}

export default ManageUsersPage;