// api-calls.js
import UserApi from "./user-api";
import ContactApi from "./contact-api";
import MessageApi from "./message-api";
import ThreadApi from "./thread-api";

const ApiCalls = {
  user: UserApi,
  contact: ContactApi,
  message: MessageApi,
  thread: ThreadApi,
};

export default ApiCalls;
