// user-api.js
import { ProtectedHttpClient, PublicHttpClient } from "../shared/clients/http-client";

const UserApi = {
  public: {
    login: (firebaseToken) =>
      PublicHttpClient.post("/auth/fb/generate-token", {
        token: firebaseToken,
      }), 
      refreshToken: (refreshToken) =>
      PublicHttpClient.post("/auth/refresh-token", {
        token: refreshToken,
      }), 
  },
  private: {
    getUserProfile: () => ProtectedHttpClient.get("/user/profile"), // Requires token
    updateUserProfile: (data) => ProtectedHttpClient.put("/user/profile", data), // Requires token
  },
};

export default UserApi;
